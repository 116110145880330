@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Lexend", sans-serif;
  color: white;
}
.selector {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.hamburger.active .bar:nth-child(2) {
  opacity: 0;
}
.hamburger.active .bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}
.hamburger.active .bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

/* Hide scrollbar for Chrome, Safari, and Opera */
/* .no-scrollbar::-webkit-scrollbar {
  display: none;
} */
/* Hide scrollbar for IE, Edge, and Firefox */
/* .no-scrollbar {
  -ms-overflow-style: none; IE and Edge */
/* scrollbar-width: none; Firefox */
/* } */
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
